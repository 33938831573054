@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body{
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px;
  letter-spacing: 0.06em;
}

.font-family-theme {
  font-family: 'Poppins', sans-serif !important;
}

.border-comments-field {
  border-color: #414963;
}

@font-face {
  font-family: 'lfm-icons';
  src:  url('../fonts/lfm-icons.eot?lpk0d');
  src:  url('../fonts/lfm-icons.eot?lpk0d#iefix') format('embedded-opentype'),
    url('../fonts/lfm-icons.ttf?lpk0d') format('truetype'),
    url('../fonts/lfm-icons.woff?lpk0d') format('woff'),
    url('../fonts/lfm-icons.svg?lpk0d#lfm-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lfm-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-delete:before {
  content: "\e91d";
}
.icon-cross:before {
  content: "\e91c";
}
.icon-github:before {
  content: "\e91b";
}
.icon-hamburger:before {
  content: "\e91a";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-backpack:before {
  content: "\e904";
}
.icon-books:before {
  content: "\e905";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chevron-up:before {
  content: "\e909";
}
.icon-church:before {
  content: "\e90a";
}
.icon-date:before {
  content: "\e90b";
}
.icon-dishes:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\e90d";
}
.icon-logo:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e90f";
}
.icon-mail:before {
  content: "\e910";
}
.icon-medical:before {
  content: "\e911";
}
.icon-moon:before {
  content: "\e912";
}
.icon-quote-close:before {
  content: "\e913";
}
.icon-quote-open:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
.icon-shalom:before {
  content: "\e916";
}
.icon-sun:before {
  content: "\e917";
}
.icon-user:before {
  content: "\e918";
}
.icon-x:before {
  content: "\e919";
}

.dropdown-toggle::after {
  font-family: "lfm-icons";
  content: "\e906" !important;
  border: 0px;
  font-size: 12px;
  margin-left: 5px;
}

.overview-img-position {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: calc(100vh + 455px);
}

.border-width-bio {
  border-width: 17px !important;
}

.w-img-bio {
  width: 19rem;
}

.w-prod-card {
  width: 121px;
  height: 121px;
}

.h-120px {
  height: 120px;
}

.top-responsive {
  top: 500px;
}

.social-media-circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav .nav-link {
  position: relative;
}
.navbar-nav .nav-link:hover::before {
  width: 100%;
}

.navbar-nav .category-nav-link {
  position: relative;
}
.navbar-nav .category-nav-link:hover::before {
  width: 100%;
}

.bg-footer {
  background-repeat: no-repeat; 
  background-position: bottom; 
  background-size: cover; 
  height: 100px;
}

.bg-top-not-found {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 200px;
}

.title-404 {
  font-size: 5.5rem;
}

.theme-switch-size {
  font-size: 30px;
}

.dropdown-categories, .dropdown-search, .dropdown-search:active, .dropdown-search:focus-visible {
  background-color: var(--dark-bg-category-bar);
}
.dropdown-search{
  color: #fff !important;
}
.dropdown-categories > li > a, .dropdown-search::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.dropdown-categories::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
}


.coldbox-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-coldbox-hover.svg");
  }
}

.commandbox-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-commandbox-hover.svg");
  }
}

.contentbox-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-contentbox-hover.svg");
  }
}

.testbox-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-testbox-hover.svg");
  }
}

.forgebox-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-forgebox-hover.svg");
  }
}

.boxlang-prod {
  text-decoration: none;
  &:hover img {
    content: url("../images/products/logo-boxlang-hover.svg");
  }
}

.pagination > li.active > a {
  color: #fff !important;
}

.dropdown-categories > li > a.active, .category-item-div > a.active {
  color: #fff !important;
  background-color: #1b233d !important;
}

.dropdown-categories > .item-category:hover, .dropdown-categories .dropdown-item:hover  {
  color: #fff !important;
  background-color: #1b233d !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .top-responsive {
    top: 300px;
  }

  .w-img-bio {
    width: 12rem;
  }
}

@media (min-width: 668px) {
  .top-responsive {
    top: 350px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .top-responsive {
    top: 450px;
  }

  .w-img-bio {
    width: 14rem;
  }
}

@media (min-width: 844px) {
  .top-responsive {
    top: 280px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .top-responsive {
    top: 300px;
  }

  .theme-switch-size {
    font-size: 20px;
  }

  .top-responsive {
    top: 400px;
  }

  .overview-height {
    height: 100%;
  }

  .rounded-mobile-bio {
    border-radius: .25rem !important;
  }

  .border-width-bio {
    border-width: 10px !important;
  }

  .w-img-bio {
    width: 17rem;
  }

  .title-404 {
    font-size: 7.5rem;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .top-responsive {
    top: 450px;
  }

  .w-img-bio {
    width: 26rem;
  }
}

/*
 **********************************************************************************************************************************
 * ******************************************************** BLOG STYLES ***********************************************************
 * ********************************************************************************************************************************
*/

#blog #categories {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: 1.5rem;
  margin-bottom: 0px;
  list-style: none;
}

.recentEntries {
  margin-bottom: 1rem;
  list-style: none !important;
}

.post a {
  text-decoration: none;
}

.paging ul li:hover a {
  color: #fff !important;
}

.post footer p {
  font-size: 1.1rem;
}

.fs-7{
  font-size: 0.75rem;
}

.bg-previous-post-card:hover {
  background-color: var(--bg-previous-post-card);
}

.bg-previous-post-card:hover h2, .bg-previous-post-card:hover a, .bg-previous-post-card:hover .border  {
  color: var(--light-link-hover-color) !important;
}

.bg-previous-post-card:hover .border  {
  border-color: var(--light-link-hover-color) !important;
}

@media only screen and (max-width:1200px) {
  .post-content img{
    max-width: 100% !important;
    height: auto !important;
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px !important;
  }
  .post-content video{
    max-width: 100% !important;
    height: auto !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .pagination li {
    margin-top: 5px;
  }
}

.max-h-15rem{
  max-height: 15rem;
}

.max-h-20rem {
  max-height: 20rem;
}

.adjust-image-size {
  display: grid;
  overflow: hidden;
  align-content: center;
}

.adsbygoogle {
  display: none !important;
}

.wrap-links {
  white-space: normal; 
  word-break: break-all;
}

#main-blog-content {
  h2, h3, h4, h5, h6 {
    color: var(--bs-blue);
    margin-top: 30px;
    margin-bottom: 30px;
  }
} 